class DelayedScript extends Component {

    constructor(el) {
        super(el);

        this.requiredCookiesConsent = Number.parseInt(this.$el.dataset.requiredConsent);
        this.content = this.$el.dataset.content;
        if (isNaN(this.requiredCookiesConsent) || !this.content) {
            return
        }

        const callback = (ev) => this.onCookiesConsentStored(ev.detail, callback);
        EventBus.getInstance().addEventListener('cookiesConsentStored', callback);
        this.onCookiesConsentStored({consent: window.cookieConsent}, callback); // Pro načítání scriptů přes ajax
    }

    /**
     * @param {{consent: number}} ev
     * @param callback
     */
    onCookiesConsentStored(ev, callback) {
        if (!this.content) {
            EventBus.getInstance().removeEventListener('cookiesConsentStored', callback)
            return
        }

        const consent = this.requiredCookiesConsent & ev.consent
        if (consent === this.requiredCookiesConsent) {
            const nodes = Util.parseStringToElements(this.content)
            this.$el.replaceWith(...nodes)
            // Odstraníme, již jsme scripty načetly
            this.content = ''
            EventBus.getInstance().removeEventListener('cookiesConsentStored', callback)
        }
    }

}
